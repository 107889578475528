import { MicrositeConfigType } from 'types';
import { AdAction } from './AdAction';
import { AppFolio } from './AppFolio';
import { ATTSpecial } from './ATTSpecial';
import { ApartmentList } from './ApartmentList';
import { BrightspeedSpecial } from './BrightspeedSpecial';
import { BuyEarthlink } from './BuyEarthlink';
import { CenturyLinkSpecial } from './CenturyLinkSpecial';
import { CincinnatiBellSpecial } from './CincinnatiBellSpecial';
import { CoxAuthorizedOffers } from './CoxAuthorizedOffers';
import { CoxCableSpecial } from './CoxCableSpecial';
import { CoxPartner } from './CoxPartner';
import { Demo } from './Demo';
import { DirecTVSpecials } from './DirecTVSpecials';
import { DishIFramed } from './DishIFramed';
import { MyDishIFramed } from './MyDishIFramed';
import { FrontierAuthorizedOffers } from './FrontierAuthorizedOffers';
import { OptimumOffers } from './OptimumOffers';
import { OrderWindstream } from './OrderWindstream';
import { SpectrumSpecial } from './SpectrumSpecial';
import { TDSAuthorizedOffers } from './TDSAuthorizedOffers';
import { Turbotenant } from './Turbotenant';
import { UpdaterInternet } from './UpdaterInternet';
import { VerizonDeals } from './VerizonDeals';
import { WOWWaySpecial } from './WOWWaySpecial';
import { QuantumFiber } from './QuantumFiber';
import { XfinityPartner } from './XfinityPartner';
import { XfinitySpecial } from './XfinitySpecial';
import { Centerfield } from './Centerfield';
import { FluentIFramed } from './FluentIFramed';

const list = [
  AdAction,
  AppFolio,
  ApartmentList,
  ATTSpecial,
  BuyEarthlink,
  Centerfield,
  CenturyLinkSpecial,
  CoxCableSpecial,
  CoxPartner,
  CoxAuthorizedOffers,
  FrontierAuthorizedOffers,
  OrderWindstream,
  BrightspeedSpecial,
  CincinnatiBellSpecial,
  OptimumOffers,
  SpectrumSpecial,
  XfinitySpecial,
  UpdaterInternet,
  DishIFramed,
  MyDishIFramed,
  WOWWaySpecial,
  TDSAuthorizedOffers,
  VerizonDeals,
  QuantumFiber,
  DirecTVSpecials,
  Turbotenant,
  XfinityPartner,
  FluentIFramed,
  Demo,
];

export { list as MicrositeList };

export * from './AdAction';
export * from './AppFolio';
export * from './ATTSpecial';
export * from './ApartmentList';
export * from './BrightspeedSpecial';
export * from './BuyEarthlink';
export * from './Centerfield';
export * from './CenturyLinkSpecial';
export * from './CincinnatiBellSpecial';
export * from './CoxAuthorizedOffers';
export * from './CoxCableSpecial';
export * from './CoxPartner';
export * from './Demo';
export * from './DirecTVSpecials';
export * from './DishIFramed';
export * from './FrontierAuthorizedOffers';
export * from './OptimumOffers';
export * from './OrderWindstream';
export * from './SpectrumSpecial';
export * from './TDSAuthorizedOffers';
export * from './Turbotenant';
export * from './UpdaterInternet';
export * from './VerizonDeals';
export * from './WOWWaySpecial';
export * from './XfinityPartner';
export * from './XfinitySpecial';
export * from './QuantumFiber';
export * from './FluentIFramed';

export function getMicrositeConfigFromCode(
  code: string
): MicrositeConfigType | undefined {
  return list.find((config) => {
    return config.micrositeCode === code;
  });
}
