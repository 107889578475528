import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  ATT,
  Brightspeed,
  BuckeyeBroadband,
  QuantumFiber as QuantumFiberProvider,
  CincinnatiBell,
  Comcast,
  Cox,
  Frontier,
  Grande,
  Mediacom,
  Optimum,
  RCN,
  Spectrum,
  TDS,
  Verizon,
  WOW,
  Wave,
  Windstream,
} from 'config/providers';
import { QuantumFiberTheme } from 'config/themes/QuantumTheme';
import { CheckoutType, ShopType, SingleProviderConfig } from 'types';

export const QuantumFiber: SingleProviderConfig = {
  name: 'QuantumFiber',
  micrositeCode: 'quantumfiber',
  mainProvider: QuantumFiberProvider,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'quantumfiberspecial.com',
  homepage: 'https://quantumfiberspecial.com',
  title: 'QuantumFiber Authorized Dealer',
  phoneNumber: '844-571-1154',
  leadModePhoneNumber: '844-571-1152',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: QuantumFiberTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'QuantumFiber',
  noOffersRetry: 2,
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.centurylinkspecial.com',
  },
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    ATT,
    Verizon,
    Brightspeed,
    Frontier,
    Windstream,
    CincinnatiBell,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    Comcast,
    Cox,
    Spectrum,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
    Mediacom,
  ],
};
