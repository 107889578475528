import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  Comcast,
  Cox,
  Spectrum,
  Mediacom,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  ATT,
  Brightspeed,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
} from 'config/providers';
import { CoxTheme } from 'config/themes';

export const CoxCableSpecial: SingleProviderConfig = {
  name: 'CoxCableSpecial',
  micrositeCode: 'cox_branded',
  mainProvider: Cox,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'coxcablespecial.com',
  homepage: 'https://coxcablespecial.com',
  title: 'Cox Authorized Dealer',
  phoneNumber: '855-731-6095',
  leadModePhoneNumber: '800-972-1372',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: CoxTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'Cox',
  noOffersRetry: 2,
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.coxcablespecial.com',
  },
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    Comcast,
    Spectrum,
    Mediacom,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    Brightspeed,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
    Verizon,
  ],
};
