import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import { AltafiberTheme } from 'config/themes';
import {
  ATT,
  Brightspeed,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
  Comcast,
  Cox,
  Spectrum,
  Mediacom,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  //Earthlink,
} from 'config/providers';

export const CincinnatiBellSpecial: SingleProviderConfig = {
  name: 'CincinnatiBellSpecial',
  // TODO: we should change this eventually...
  micrositeCode: 'Cincinnati Bell',
  mainProvider: CincinnatiBell,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'cincinnatibellspecial.com',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: AltafiberTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'CincinnatiBell',
  homepage: 'https://cincinnatibellspecial.com',
  title: 'Cincinnati Bell Authorized Dealer',
  phoneNumber: '855-446-1941',
  leadModePhoneNumber: '800-972-6964',
  noOffersRetry: 2,
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.cincinnatibellspecial.com',
  },
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    ATT,
    Verizon,
    Brightspeed,
    CenturyLink,
    Frontier,
    Windstream,
    //Earthlink,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    Comcast,
    Cox,
    Spectrum,
    Mediacom,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
  ],
};
