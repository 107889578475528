import { useFeature } from '@growthbook/growthbook-react';
import { TvInternetOffer } from '@updater/consumer-graph/';
import { useOfferContext } from '@updater/ui-widgets';

export const useShouldShowHighlightsOverhaulExperiment = () => {
  const offer = useOfferContext() as TvInternetOffer;
  const isBasicDummyOffer = Boolean(
    offer?.metadata?.dummy && offer?.metadata?.dummyOfferType === 'basic'
  );
  // https://updater.atlassian.net/browse/TVI-635
  const { on: planHighlightsOverhaulEnabled } = useFeature(
    'provider_app_plan_highlights_overhaul'
  );

  const hasInternet = Boolean(offer?.products?.internet?.downloadSpeed);
  const showHighlightsOverhaul =
    !isBasicDummyOffer &&
    hasInternet &&
    planHighlightsOverhaulEnabled &&
    isHighlightsOverhaulEnabledForProvider({
      providerCode: offer?.providerCode,
    });

  return { showHighlightsOverhaul };
};

export interface isHighlightsOverhaulEnabledForProviderArgs {
  providerCode: string;
}

export const OverhaulExperimentProviderCodes = [
  'att',
  'xfinity',
  'spectrum',
  'cox',
  'verizon',
  'centurylink',
  'windstream',
  'tds',
  'frontier',
  'mediacom',
  'altafiber',
  'brightspeed',
  // wave, rcn, grande, and astound are the same - https://upd-home-platform.slack.com/archives/C0711HQ3S81/p1723844606537309
  'wave',
  'rcn',
  'grande',
  'astound',
];

export const isHighlightsOverhaulEnabledForProvider = ({
  providerCode,
}: isHighlightsOverhaulEnabledForProviderArgs) =>
  OverhaulExperimentProviderCodes.includes(providerCode);
