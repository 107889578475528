import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import {
  allLeadMode,
  getDefaultCrossSellAction,
  spectrumCrossSellUrl,
} from 'config';
import {
  Comcast,
  Cox,
  Spectrum,
  Mediacom,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  ATT,
  Brightspeed,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
} from 'config/providers';
import { SpectrumTheme } from 'config/themes';

export const SpectrumSpecial: SingleProviderConfig = {
  name: 'SpectrumSpecial',
  micrositeCode: 'spectrum',
  mainProvider: Spectrum,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'spectrumspecial.com',
  homepage: 'https://spectrumspecial.com',
  title: 'Spectrum Authorized Dealer',
  phoneNumber: '866-282-1155',
  leadModePhoneNumber: '866-428-6055',
  defaultCheckoutType: CheckoutType.PROVIDER_REDIRECT,
  defaultTheme: SpectrumTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'Spectrum',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    Comcast,
    Cox,
    Optimum,
    Mediacom,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
  ],
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.xfinityspecial.com',
  },
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    Brightspeed,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
    Verizon,
  ],
  getProviderRedirectUrl: spectrumCrossSellUrl,
};
